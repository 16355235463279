[data-sidebar="open"] {
    > * {
        pointer-events: none;
    }
}

.sc-megamenu {
    @include media-breakpoint-down(xl) {
        position: fixed;
        top: 60px;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateY(100%);
        z-index: 15000;
        overflow: hidden;
        border-top-right-radius: 0.75rem;
        border-top-left-radius: 0.75rem;
        background: #f2f4f8;
        transition: transform $mainTransition;

        &-list {
            height: 100%;
            max-height: calc(100% - 56px);
            overflow-x: hidden;
            overflow-y: auto;
            border: 0 !important;
            background: #f2f4f8;

            &-item {
                padding: 1rem;

                &:not(:first-child) {
                    border-top: 1px solid #f2f2f2;
                }

                .sc-btn > * {
                    pointer-events: none;
                }

                > a img {
                    width: 28px;
                    height: 28px;
                }
            }
        }

        &-child {
            z-index: 100;
            background: #f2f4f8;

            &.active {
                transform: translateX(0);
            }

            .sc-megamenu-child.active {
                transform: translateX(-100%);
            }

            &-landings {
                display: flex;
                flex-wrap: wrap;
                gap: 0.5rem 1rem;

                > * {
                    flex: 0 0 calc(50% - 0.5rem);
                }
            }
        }

        &.active {
            transform: translateY(0);

            .sc-megamenu-list {
                border-radius: 0;
            }
        }

        &-children {
            column-count: 2;

            &-item {
                img {
                    background: #fff;
                    border: 1px solid #f2f2f2;
                    border-radius: 0.5rem;
                    padding: 0.5rem;
                }
            }
        }

        .sidebar-header {
            background: #FFFFFF;
            font-size: 1rem;
        }
    }

    > nav {
        flex: 0 0 auto;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;

        @include media-breakpoint-up(xl) {
            width: 306px;
        }

        @media (min-width: $break-xxl) {
            width: 376px;
        }
    }

    @include media-breakpoint-up(xl) {
        right: calc(100% - 376px);
        position: absolute;
        top: 100%;
        left: 0.75rem;
        opacity: 0;
        visibility: hidden;
        transition: opacity $mainTransition;
        padding-top: 0.5rem;

        &.active,
        &.with-slideshow {
            opacity: 1;
            visibility: visible;
        }

        &.with-slideshow {
            transition: unset;
            padding-top: 2rem;

            > nav:hover {
                overflow-y: hidden;
            }

            > div > nav {
                height: 100%;
            }

            .sc-megamenu-list {
                transition: max-height $mainTransition;
                overflow: hidden;
                height: 100%;

                &.without-day-products {
                    position: relative;
                    overflow-y: auto;

                    &:hover {
                        position: static;
                    }
                }

                &:hover {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    overflow-y: auto;
                }

                &-item >.sc-megamenu-child {
                    top: 2rem;
                }
            }
        }

        &:not(.with-slideshow) {
            z-index: 11000;

            nav {
                max-height: calc(100vh - 140px);
            }
        }

        &.active {
            .sc-megamenu-list {
                height: 100% !important;
            }
        }
    }

    &.active .sc-megamenu-list {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &-list {
        border: $mainBorder;
        border-radius: 4px;

        @include media-breakpoint-up(xl) {
            background: #FFFFFF;
        }

        &-item {
            @include media-breakpoint-up(xl) {
                padding: 0.25rem 0.75rem;

                & + & {
                    margin-top: 0.5rem;
                }
            }

            > a {
                font-weight: 500;
                cursor: pointer;
            }

            > span {
                cursor: pointer;
            }

            > span {
                width: 1.5rem;
                height: 1.5rem;
                background: transparent;
                transform: rotate(-90deg);
                cursor: default !important;
            }

            &:hover,
            &.active {
                > a {
                    font-weight: 700;
                }

                > span {
                    background: $primaryLightColor;
                }

                > .sc-megamenu-child {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    &-child {
        position: absolute;
        top: 0;
        left: 0;

        @include media-breakpoint-down(xl) {
            right: 0;
            bottom: 0;
            width: 100%;
            min-height: 100%;
            transform: translateX(100%);
            transition: transform $mainTransition;
        }

        @include media-breakpoint-up(xl) {
            background: #FFFFFF;
            opacity: 0;
            visibility: hidden;
            top: calc(0.5rem + 1px);
            width: 894px;
            left: 306px;
            transition: opacity $mainTransition 0.05s, visibility $mainTransition 0.05s;
            box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
            border-radius: 0px 4px 4px 0;
        }

        @media (min-width: $break-xxl) {
            left: 376px;
            width: 1200px;
        }

        &-narrow {
            right: auto;
            border-radius: 0;
            bottom: 0;

            @include media-breakpoint-up(xl) {
                width: 306px;
            }

            @media (min-width: $break-xxl) {
                width: 376px;
            }

            .sc-megamenu-child-wrapper {
                padding: 0;
            }

            ul {
                max-height: calc(100% - 56px);
                overflow-y: auto;

                @include media-breakpoint-up(xl) {
                    max-height: 100%;
                }
            }

            li:not(.sc-menu-list-landings) {
                padding: 1rem;

                @include media-breakpoint-down(xl) {
                    &:not(:first-child) {
                        border-top: 1px solid #f2f2f2;
                    }
                }
                
                @include media-breakpoint-up(xl) {
                    padding: 0.75rem 0.75rem 0.75rem 1.5rem;
                }

                & + li {
                    border-top: $mainBorder;
                }

                > span:not(.sc-megamenu-children-title) {
                    width: 20px;
                    height: 20px;
                    background: rgba(0, 0, 0, 0);
                    transform: rotate(-90deg);
                    cursor: default !important;
                }

                .sc-megamenu-child {
                    top: 0;
                }

                .sc-menu-list-landings-link:hover {
                    color: $linksColor;
                    text-decoration: underline;
                }

                &:hover {
                    > a:not(.sc-menu-list-landings-link) {
                        font-weight: 700;
                    }

                    > span:not(.sc-megamenu-children-title) {
                        background: $primaryLightColor;
                    }

                    > .sc-megamenu-child {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            .sc-megamenu-child-narrow {
                @include media-breakpoint-down(xl) {
                    width: 100%;
                    left: 100%;
                }
            }
        }

        &-wrapper {
            padding: 1rem 1rem 0;
            overflow-y: auto;
            scroll-behavior: smooth;
            z-index: 1;
            max-height: calc(100% - 56px);

            @include media-breakpoint-up(xl) {
                padding: 2rem;
                max-height: calc(100vh - 9rem);
            }

            .sticky-top {
                top: 1.5rem;
            }
        }
    }

    &-children {
        column-count: 2;

        @include media-breakpoint-up(xl) {
            column-count: 3;
        }

        &-manufacturers {
            column-count: 2;

            @include media-breakpoint-up(xl) {
                column-count: 5;
            }
        }

        &-item {
            padding-bottom: 1.5rem;
            page-break-inside: avoid;
            break-inside: avoid;

            &-title {
                margin-bottom: 0.75rem;
            }

            li {
                margin-top: 0.25rem;
            }

            a:hover {
                text-decoration: underline;
                color: $linksColor;
            }
        }
    }
}